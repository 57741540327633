.loader-dimer {
  background-color: rgba(255, 255, 255, .85);
  border-radius: 10px;
}

.loader-size {
  width: "3rem";
  height: "3rem";
}

.highlight-container {
  background-color: #e9ecef;
}